import React from 'react'
import {Route, Navigate, createRoutesFromElements} from 'react-router-dom'
import KycOverview from './pages/kyc-overview/kyc-overview'
import CompanyGrouping from './pages/multi-entity/company-grouping'
import {PageNotFound} from 'components/page-message'
import Whitelist from 'pages/whitelist/whitelist'
import ConnectCompany from 'pages/connect-company/connect-company'
import GenerateDemo from 'pages/demo-accounts/generate-demo/generate-demo'
import ListDemoAccounts from 'pages/demo-accounts/list-demo-accounts/list-demo-accounts'
import Offboarding from 'pages/offboarding/offboarding'
import CustomerInformation from 'pages/customer-information/customer-information'
import {Providers} from 'providers'

export const getRoutes = () =>
    createRoutesFromElements(
        <Route element={<Providers />}>
            <Route path="/kyc-overview/:id" element={<KycOverview />} />
            <Route path="/manage-company-group/*" element={<CompanyGrouping />} />
            <Route path="/whitelist" element={<Whitelist />} />
            <Route path="/connect-company" element={<ConnectCompany />} />
            <Route path="/offboarding/:id" element={<Offboarding />} />
            <Route path="/generate-demo" element={<GenerateDemo />} />
            <Route path="/list-demo-accounts" element={<ListDemoAccounts />} />
            <Route path="/customer-information/:id" element={<CustomerInformation />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route element={<Navigate replace to="/404" />} />
        </Route>
    )
