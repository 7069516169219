import React, {Suspense} from 'react'
import TokenProvider from 'providers/token-provider'
import {theme} from 'theme/theme'
import {ThemeProvider} from 'styled-components'
import ErrorBoundary from 'providers/error-boundary'
import {SWRConfig} from 'swr'
import {Skeleton} from 'antd'
import {Outlet} from 'react-router-dom'

export function Providers() {
    return (
        <SWRConfig value={{suspense: true}}>
            <Suspense fallback={<Skeleton />}>
                <ErrorBoundary>
                    <TokenProvider>
                        <ThemeProvider theme={theme}>
                            <Outlet />
                        </ThemeProvider>
                    </TokenProvider>
                </ErrorBoundary>
            </Suspense>
        </SWRConfig>
    )
}
