import {matchRoutes} from 'react-router-dom'
import config from 'config'

import {
    createReactRouterV6DataOptions,
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration
} from '@grafana/faro-react'
import {TracingInstrumentation} from '@grafana/faro-web-tracing'

export function initGrafanaFaro() {
    initializeFaro({
        url: config.grafana.collectorUrl,
        app: {
            name: config.grafana.appName,
            environment: config.grafana.env
        },

        instrumentations: [
            // Mandatory, omits default instrumentations otherwise.
            ...getWebInstrumentations(),

            // Tracing package to get end-to-end visibility for HTTP requests.
            new TracingInstrumentation(),

            // React integration for React applications.
            new ReactIntegration({
                router: createReactRouterV6DataOptions({
                    matchRoutes
                })
            })
        ]
    })
}
