import React from 'react'

import {useCreateDemoAccount} from 'services/demo-accounts'

import Loading from './loading-result/loading-result'
import {Alert, Space} from 'antd'
import {WarningOutlined} from '@ant-design/icons'
import Link from 'antd/lib/typography/Link'
import Text from 'antd/lib/typography/Text'

const GenerateDemo = () => {
    const {loading} = useCreateDemoAccount()

    if (loading) {
        return <Loading />
    }
    return (
        <>
            <Alert
                type="warning"
                icon={<WarningOutlined />}
                message="Deprecation Notice"
                description={
                    <Space direction="vertical" size="middle">
                        <Text>Generating Pleo demo accounts from Hubspot is deprecated.</Text>
                        <div>
                            <Text strong>Please use either: </Text>
                            <Space direction="vertical" size="small" className="mt-2">
                                <div>
                                    <Link href="https://backoffice.pleo.io/product/demo-accounts">
                                        https://backoffice.pleo.io/product/demo-accounts
                                    </Link>
                                    <Text type="secondary"> (Production backoffice)</Text>
                                </div>
                                <div>
                                    <Link href="https://backoffice.staging.pleo.io/product/demo-accounts">
                                        https://backoffice.staging.pleo.io/product/demo-accounts
                                    </Link>
                                    <Text type="secondary"> (Staging backoffice)</Text>
                                </div>
                            </Space>
                        </div>
                    </Space>
                }
                style={{
                    maxWidth: '800px'
                }}
            />
        </>
    )
}

export default GenerateDemo
