import React from 'react'
import {createRoot} from 'react-dom/client'
import {getRoutes} from './router'

import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {withFaroRouterInstrumentation} from '@grafana/faro-react'
import {initGrafanaFaro} from 'providers/grafana'

import 'antd/dist/antd.css'

const router = createBrowserRouter(getRoutes(), {
    basename: '/hubspot'
})

const browserRouter = withFaroRouterInstrumentation(router)
const root = createRoot(document.getElementById('root') as HTMLElement)

initGrafanaFaro()
root.render(<RouterProvider router={browserRouter} />)
