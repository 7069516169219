import React, {FC, createContext, useContext} from 'react'
import {setRequestToken} from 'services/request/handler'
import {parse} from 'qs'
import createPersistedState from 'use-persisted-state'
import {InvalidToken} from 'components/page-message'
import {notification} from 'antd'
import type {ParsedQs} from 'qs'

export const useTokenState = createPersistedState('token')

export const useKycState = createPersistedState('kyc')

const setSessionStorageItemIfNeeded = (params: any, item: string) => {
    const sessionItem = window.sessionStorage.getItem(item)
    if (params?.[item] && (!sessionItem || sessionItem !== params[item])) {
        window.sessionStorage.setItem(item, `${params[item]}`)
    }
}

type TokenContextAPI = {
    token: string[] | string | boolean | ParsedQs | ParsedQs[] | unknown
}

const TokenContext = createContext<TokenContextAPI>({
    token: ''
})

export function useToken() {
    const {token} = useContext(TokenContext)

    return token
}

const TokenProvider: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({
    children
}) => {
    const [token, setToken] = useTokenState('')
    const [kyc, setKyc]: any = useKycState({
        lastUpdatedKyc: ''
    })
    const hash = window.location.hash
    const query = hash.match(/#(.*)#/)?.[1] || ''
    const params = parse(query)

    const noTokenInLocalStorage = Boolean(!token)
    const oldTokenInLocalStorage = params?.token && token && token !== params?.token
    const setPersistedStateSinceKycInfoChanged =
        params?.last_updated_kyc &&
        kyc?.lastUpdatedKyc &&
        params.last_updated_kyc !== kyc.lastUpdatedKyc

    if (params?.token && (noTokenInLocalStorage || oldTokenInLocalStorage)) {
        try {
            setToken(`${params?.token}`)
        } catch (e) {
            if (e instanceof Error) {
                notification.error({
                    message: 'Failed to set token',
                    description: e?.message
                })
            }
        }
    }

    if (token) {
        try {
            setRequestToken(String(token))
        } catch (e: any) {
            notification.error({
                message: 'Failed to set request token',
                description: e?.message
            })
        }
    }

    setSessionStorageItemIfNeeded(params, 'company')
    setSessionStorageItemIfNeeded(params, 'email')
    setSessionStorageItemIfNeeded(params, 'name')
    setSessionStorageItemIfNeeded(params, 'portalId')

    if (setPersistedStateSinceKycInfoChanged) {
        try {
            setKyc({lastUpdatedKyc: `${params.last_updated_kyc}`})
        } catch (e: any) {
            notification.error({
                message: 'Failed to set last updated kyc',
                description: e?.message
            })
        }
    }
    if (hash) {
        window.location.href = window.location.href.replace(/#.*$/, '')
    }
    if (!token) {
        return <InvalidToken />
    }
    return <TokenContext.Provider value={{token: token}}>{children}</TokenContext.Provider>
}

export default TokenProvider
